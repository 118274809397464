<template>
  <div class="fs-container">
    <div class="fs-section fs-article">
      <div class="banner-box">
        <div class="fs-pic banner-pic">
          <img :src="BlogDetail.BannerImage" :alt="BlogDetail.Title" />
        </div>
        <div class="banner-info">
          <h1 v-html="BlogDetail.Title"></h1>
          <p>
            <strong
              ><span class="color-red">{{ BlogDetail.Tagging }}</span> NEWS
              {{ BlogDetail.month_ }}</strong
            >
            {{ BlogDetail.days }}
            DAYS AGO
          </p>
        </div>
      </div>

      <div class="fs-inr article-inr">
        <div class="acticle-detail">
          <div class="acticle-top">
            <div class="user-box">
              <div class="user-pic">
                <img :src="BlogDetail.AuthorIcon" :alt="BlogDetail.Author" />
              </div>
              <div class="user-txt">{{ BlogDetail.Author }}</div>
            </div>
            <div class="social">
              <div class="social-inr">
                <div
                  class="fb-like"
                  :data-href="currentUrl"
                  data-width=""
                  data-layout="button"
                  data-action="like"
                  data-size="large"
                  data-share="true"
                ></div>
              </div>
              <div class="tag">TAG</div>
            </div>
          </div>

          <div class="article-content">
            <img
              v-if="BlogDetail.DetailImage != null"
              :src="BlogDetail.DetailImage"
              :alt="BlogDetail.Title"
            />
            <div class="detail-wrap" v-html="BlogDetail.Detail"></div>
          </div>
          <div class="social-box">
            <div class="social-inr">
              <div
                class="fb-like"
                :data-href="currentUrl"
                data-width=""
                data-layout="button"
                data-action="like"
                data-size="large"
                data-share="true"
              ></div>
            </div>
          </div>

          <div class="news-relation">
            <h2>{{ $t("Common.Next") }}</h2>
            <div class="relation-items">
              <router-link
                class="relation-item"
                v-for="blog in ListBlogItemRelated"
                :key="blog.Id"
                :to="
                  $t('Route.INews') +
                  '/' +
                  blog.BlogCategorySlug +
                  '/' +
                  blog.Slug
                "
              >
                <div class="fs-pic">
                  <img :src="blog.Thumbnail" :alt="blog.Title" />
                </div>
                <div class="fs-txt">
                  <span class="brand">{{ blog.Tagging }}</span>
                  <h3 v-html="blog.Title"></h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div class="news-randoms">
          <h2>{{ $t("Common.Random article") }}</h2>
          <div class="random-items">
            <router-link
              class="news-item"
              v-for="(blog, i) in ListBlogItemRandom"
              :key="blog.Id"
              :to="
                $t('Route.INews') +
                '/' +
                blog.BlogCategorySlug +
                '/' +
                blog.Slug
              "
            >
              <div
                class="fs-bg cmBg"
                :style="'background-image:url(' + blog.Thumbnail + ')'"
              ></div>
              <div v-if="i == 0" class="fs-txt">
                <div class="user-box">
                  <div class="user-pic">
                    <img :src="blog.AuthorIcon" :alt="blog.Author" />
                  </div>
                  <div class="user-txt">{{ blog.Author }}</div>
                </div>
                <h3 v-html="blog.Title"></h3>
              </div>
              <div v-else class="fs-txt">
                <h3 v-html="blog.Title"></h3>
                <!-- <span class="hot-caption">{{blog.ReadTime}} Min Read</span> -->
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import { isMobile } from "mobile-device-detect";

// Import Swiper styles
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      BlogDetail: {},
      ListBlogItemRelated: [],
      ListBlogItemRandom: [],
      videoId: "",
      modalClass: "",
      Slug: "",
      currentUrl: "",
    };
  },
  watch: {
    $route(to, from) {
      this.fetch();
    },
  },
  created() {
    this.currentUrl = window.location.href;
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    this.setMetaData(false);
    this.setAnimation();
    this.fetch();
    var t_ = this;
    EventBus.$on("CHANGE_LANG", function (payLoad) {
      t_.fetch();
    });
  },
  methods: {
    Img(pic) {
      return images[pic];
    },
    fetch() {
      this.loading(true);
      useJwt
        .post("getMasterData/BlogDetail", {
          columnFilters: { Slug: this.$route.params.Id },
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.BlogDetail = response.data.result.BlogDetail[0];
          this.ListBlogItemRelated = response.data.result.ListBlogItemRelated;
          this.ListBlogItemRandom = response.data.result.ListBlogItemRandom;
          this.setMetaData(true);
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    setMetaData(insideFetch) {
      if (!this.BlogDetail) {
        return;
      }

      console.log("set meta", insideFetch);

      let title, desc, imgUrl, url;

      if (insideFetch) {
        imgUrl = this.BlogDetail.BannerImage;
        title = this.BlogDetail.Title;
        url = window.location.href;
        desc = "itel.vn";
      } else {
        title = "[Test prerender] Trao giải mừng 3 triệu thuê bao iTel";
        desc =
          "[Test prerender] Chương trình “Chào đón thuê bao 3 triệu – Trao ngay SIM vàng hiệu” là sự kiện đánh dấu mốc phát triển quan trọng của iTel khi nhà mạng vượt ngưỡng 3 triệu thuê bao tại Việt Nam.";
        imgUrl =
          "https://gatorworks.net/wp-content/uploads/2020/06/shared-image.png";
        url =
          "https://test.itel.vn/tin-tuc/tin-tuc-itel/trao-giai-mung-3-trieu-thue-bao-itel-tang-chu-nhan-giai-thuong-sim-vang-tri-gia-300.000.000-vnd";
      }

      const metas = document.getElementsByTagName("meta");
      for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute("property") == "og:image") {
          metas[i].setAttribute("content", imgUrl);
        }
        if (metas[i].getAttribute("property") == "og:title") {
          metas[i].setAttribute("content", title);
        }
        if (metas[i].getAttribute("property") == "og:url") {
          metas[i].setAttribute("content", url);
        }
        if (metas[i].getAttribute("property") == "og:description") {
          metas[i].setAttribute("content", desc);
        }
        if (metas[i].getAttribute("name") == "description") {
          metas[i].setAttribute("content", this.BlogDetail.SeoDetails);
        }
      }

      const titles = document.getElementsByTagName("title");
      titles[0].innerHTML = this.BlogDetail.SeoTitle;

      // // SeoDetails
      // const description = document.getElementsByTagName("description");
      // description.innerHTML = this.BlogDetail.SeoDetails;
    },
    openVideo(url) {
      this.modalClass = "modal__open";
      this.videoId =
        "https://www.youtube.com/embed/" +
        url +
        "?rel=0&amp;autoplay=1&amp;playsinline=1";
    },
    closeVideo() {
      this.videoId = "";
      this.modalClass = "";
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector(".fs-header").clientHeight;
      [].slice
        .call(
          document.querySelectorAll(
            ".fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr"
          )
        )
        .forEach((elm) => {
          const style = window.getComputedStyle(elm, null);
          const elmH = style.getPropertyValue("height");
          const inputT = windowH - elm.getBoundingClientRect().top;
          if (inputT > 0 && inputT < windowH + parseInt(elmH, 10) - headH / 2) {
            elm.classList.add("fs-ani");
          } else {
            elm.classList.remove("fs-ani");
          }
        });
    },
  },
};
</script>
